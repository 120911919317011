@import "./antd-reduced.less";
@import "./config/styles/theme.less";
@import "./config/styles/base.less";
@import "./config/styles/antd-overrides.less";

* {
  font-family: 'Noto Sans', 'san-serif' !important;
}

.st-layout {
  height: 100vh;

  .st-sider {
    background-color: #232630;
  }

  .st-content {
    background-color: #f5f8fc;
    z-index: 1;
  }
}

.navbarLogo {
  margin: 0px 8px 0px 0px !important;
}

.CropwiseLogo {
  text-align: center;
}

.syt-antd-popover-inner-content {
  padding: 5px 0px 20px 0px !important;
}

.cw-deck-of-apps-footer .syt-antd-divider-horizontal {
  margin: 12px 0;
}

.syngenta-ant-layout-sider-children {
  height: 100vh !important;
}

.syngenta-ant-layout {
  background: #e5e5e5;
}

.syngenta-ant-menu-inline-collapsed {
  width: auto !important;
}

.submenuName {
  margin-top: 7px !important;
}

.syt-antd-avatar-circle {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  color: rgb(77, 81, 101);
  display: block;
  cursor: pointer;
  background: rgb(243, 244, 246);
  font-weight: 600;
  text-align: center;
}

.navbarDivider {
  display: none;
}

.syngenta-ant-layout-sider-trigger {
  background-color: #14151c !important;
}

.syngenta-ant-layout-sider,
.syngenta-ant-menu.syngenta-ant-menu-dark {
  background-color: #232630 !important;
}

.triggerCloseButton {
  margin-top: 13px !important;
  margin-right: 13px !important;
}

.triggerExpandButton {
  margin-top: 13px !important;
}

.main-layout {
  height: calc(100vh - 56px);
  min-height: 100%;
}

.main-container {
  background-color: #e5e5e5 !important;
  overflow-y: auto;
  overflow-x: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.navbarUserArea {
  padding-right: 0px !important;
  margin-top: 7px !important;
}

.loader {
  border: 4px solid #dfe2e7;
  border-radius: 50%;
  border-top: 4px solid #14803c;
  width: 36px;
  height: 36px;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

.loader-wrapper {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  top: 45%;
  z-index: 9999999;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader-text {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 10px;
}

.mask {
  background: #14151c;
  opacity: 0.5;
  right: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 999999;
}

.app-root {
  height: 100%;
  min-height: 100%;

  * {
    box-sizing: border-box;
  }
}

.syt-antd-breadcrumb {
  padding: 0px 0px 0px 15px;
  background-color: #ffffff;
  font-size: 20px;
  line-height: 65px;
  border-top: 1px solid #dfe2e7;
  border-bottom: 1px #dfe2e7;
  word-wrap: break-word;

  ol {
    margin: 0px;
    padding: 0px;
  }

  li {
    display: inline;
  }
}

.syt-antd-breadcrumb,
.syt-antd-breadcrumb .syt-antd-breadcrumb-link a {
   color: #000 !important;
  font-weight: 700 !important;
}
.syt-antd-breadcrumb-link  {
  display: inline-flex;
}

.syt-antd-breadcrumb li:last-child {
  span {
    pointer-events: none;
    font-size: 24px;
  }

  .syt-antd-breadcrumb-separator {
    display: none;
    padding: 0px;
  }
}

.syt-antd-breadcrumb-separator {
  font-size: 24px;
  margin: 0 10px;
}

.syngenta-ant-layout.cw-module-inner-display {
  position: relative;
  height: calc(100vh - 116px);
}

.syngenta-ant-layout.page-content {
  height: calc(100vh - 132px);
  margin: 24px;
}

.height-full {
  height: calc(100vh - 300px);
}

.syt-antd-breadcrumb span {
  text-transform: capitalize;
}

.syngenta-ant-layout-content .main-layout {
  height: calc(100vh - 56px) !important;
}

.defaultButton {
  border: 1px solid #c2c7d0;
  border-radius: 4px;
  padding: 4px 18px;
  cursor: pointer;
}

.create-edit-card-footer {
  button {
    border-radius: 4px;
    padding: 4px 18px;
    cursor: pointer;
  }

  .defaultButton {
    border: 1px solid #c2c7d0;
  }

  .btn-green,
  .btn-green:focus,
  .btn-green:hover {
    background-color: #14803c;
    border: 1px solid #14803c;
    color: #ffffff !important;
  }
}

.marginAuto {
  margin: auto;
}

.syngenta-ant-input {
  color: #2f3031;
  border-color: #c2c7d0 !important;
}

.syngenta-ant-input:focus {
  color: #2f3031;
  border-color: #c2c7d0 !important;
}

.syngenta-ant-input-focused {
  color: #2f3031;
  border-color: #c2c7d0 !important;
}

.syngenta-ant-input-affix-wrapper {
  border: 1px solid #c2c7d0 !important;
}

.syngenta-ant-input-affix-wrapper:focus {
  border: 1px solid #c2c7d0 !important;
  box-shadow: none !important;
}

.syngenta-ant-input-affix-wrapper-focused {
  border: 1px solid #c2c7d0 !important;
  box-shadow: none !important;
}

.syngenta-ant-layout-content main-container {
  margin: 0px;
  padding: 0px;
}

.syngenta-ant-modal-close-x {
  display: block !important;
  width: 32px !important;
  height: 15px;
  font-size: 20px;
  font-style: normal;
  line-height: 20px !important;
  text-transform: none;
  text-rendering: auto;
}

.syngenta-ant-modal-close:hover {
  color: rgba(0, 0, 0, 0.88);
  background-color: #ffffff !important;
  text-decoration: none;
}

.syngenta-ant-form-item-explain {
  margin-top: 3px;
  margin-bottom: 3px;
}
@ant-prefix: syngenta-ant;