.title{
    font-family: "Noto Sans",sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -.0075em;
    color: #14803c;
    text-align: center;
    margin-bottom: 0;
}

.sub-title-text{
    font-family: "Noto Sans",sans-serif;
    color: #14151c;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -.0075em;
    text-align: center;
    margin-bottom: 0;
}

.not-request-access-gif{
    width: 100%;
}

.text-content-container{
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 276px;
}

.newRequestAccessModal .action-container, .syt-antd-modal-body .action-container {
    height: 72px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 20px;
}

.newRequestAccessModal  .syngenta-ant-modal-body{
    padding:0px !important
}

.newRequestAccessModal .action-container .access-request-sent-message-container, .syt-antd-modal-body .action-container .access-request-sent-message-container {
    flex: 1 1;
}

.newRequestAccessModal .action-container .enableButton, .syt-antd-modal-body .action-container .enableButton {
    background-color: #14803c;
    color: #fff;
}

.newRequestAccessModal .action-container .disableButton, .syt-antd-modal-body .action-container .disableButton {
    border-color: #c4bfbf;
}


.newRequestAccessModal .action-container .access-request-sent-message-container .request-sent-message-text, .syt-antd-modal-body .action-container .access-request-sent-message-container .request-sent-message-text {
    text-align: center;
    margin-left: 24%;
    padding: 10px;
    margin-right: 8%;
    background: #f3f4f6;
    border: 1px solid #dfe2e7;
    font-family: "Noto Sans",sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -.0075em;
}

.logout-unauthorized{
    background-color: #14803c;
    color: #fff;
}
#request-modal__footer-actions {
    position: relative;
    right: 10px
}
@ant-prefix: syngenta-ant;