.st-grid {
  display: grid;
}

@range: 4 8 12 16 24;
.grid-gap-loop (@ranges, @i: 1) when (@i <= length(@ranges)) {
  @size: extract(@ranges, @i);

  .st-grid-row-gap--@{size} {
    grid-row-gap: ~'@{size}px';
  }

  .st-grid-column-gap--@{size} {
    grid-column-gap: ~'@{size}px';
  }

  .grid-gap-loop(@range, @i + 1);
}
.grid-gap-loop(@range);

@iterations: 5;
.grid-loop (@i) when (@i > 0) {
  .st-grid--@{i} {
    display: grid;
    height: inherit;
    grid-template-columns: repeat(~'@{i}', auto);
  }
  .st-grid-fr--@{i} {
    display: grid;
    height: inherit;
    grid-template-columns: repeat(~'@{i}', 1fr);
  }
  .st-grid-min--@{i} {
    display: grid;
    grid-template-columns: repeat(~'@{i}', min-content);
  }
  .st-grid-item-start--@{i} {
    grid-column-start: @i;
  }
  .st-grid-item-end--@{i} {
    grid-column-end: @i;
  }
  .st-grid-row--@{i} {
    display: grid;
    grid-template-rows: repeat(~'@{i}', auto);
  }

  .grid-loop(@i - 1);
}

.grid-loop (@iterations);

@positions: start, end, center, left, right;
.grid-align-loop (@positions, @i: 1) when (@i <= length(@positions)) {
  @position: extract(@positions, @i);

  .st-self-align--@{position} {
    align-self: @position;
  }
  .st-self-justify--@{position} {
    justify-self: @position;
  }
  .st-align-items--@{position} {
    align-items: @position;
  }
  .st-justify-content--@{position} {
    justify-content: @position;
  }
  .st-text-align--@{position} {
    text-align: @position;
  }

  .grid-align-loop(@positions, @i + 1);
}
.grid-align-loop(@positions);

.st-action {
  cursor: pointer;

  &-btn:hover {
    cursor: pointer;
    background-color: var(--pale-grey);
  }

  &-btn-reverse:hover {
    cursor: pointer;
    background-color: white;
  }
}

.st-tag {
  height: 16px !important;
  display: flex !important;
  padding: 4px 4px !important;
  align-items: center;
  span {
    padding-top: 1px;
  }
}

.st-default-line-height {
  line-height: 14px;
}

.st-page-header {
  display: grid;
  align-items: center;
  width: 100%;
  padding-left: 12px;
  background-color: white;
  height: 56px;
  margin-bottom: 20px;
}

.sf-bin-outline {
  &:hover {
    fill: #f85555;
  }
}

.sf-spray {
  &:hover {
    fill: #0670fa;
  }
}

.st-force-font-color {
  color: #2f3031 !important;
}

.st-page-title {
  display: grid;
  align-items: center;
  background-color: #F0F3F7;
  height: 56px;
  padding: 16px;
}

.st-table-button {
  padding: 0 8px;
  background: #f2f4f6;
  border: 0;
  margin-right: 8px;
  &:hover {
    background: #f2f4f6;
  }
}

.verticallyCenter {
  display    : flex;
  align-items: center;
}

.createMultiChoiceQuestion,
.createNewDriver,
.editOutcome,
.createQuestion,
.create-yes-no-question
  .@{ant-prefix}-form-item-explain {
    padding-top: 10px !important;
  }
.textWidth {
  width: 100%;
}

.title-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.card__title{
  padding-left: 20px;
}

.text-color {
  color: #14151C !important;
}

.questionTypeLabel,
.driver__skiplogic>.@{ant-prefix}-form-item-label>label {
  font-family: 'Noto Sans', sans-serif;
  font-style : normal;
  font-weight: 400;
  font-size  : 14px;
  line-height: 16px;
  color      : #696f88 !important;
}

.@{ant-prefix}-form-item-control{
  width: fit-content;
}

.template__save-template-modal--description-text {
    margin-top: 20px;
    margin-bottom: 30px;
}
.@{ant-prefix}-pagination-item {
  display: none;
}
.@{ant-prefix}-pagination-prev {
  margin-right: 20px !important;
}

.@{ant-prefix}-pagination-next {
  margin-right: 10px !important;
}

.@{ant-prefix}-switch-checked{
  background-color:#026502;
}
.@{ant-prefix}-pagination-options-size-changer.syngenta-ant-select{
  margin-right: 0px;
}

.divider {
  margin: 0px 0px 10px 0px
}
.card-body--padding {
  margin: 15px 24px;
}
.syngenta-ant-table-thead .syngenta-ant-table-cell {
  text-align: center;
}

.@{ant-prefix}-select-arrow {
  color: #0071cd;
  padding-left: 0px !important;
}

.search-bar-with-filter-section {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.search-bar {
  max-width: 30%;
  min-width: 20%;
  height: 50px;
  width: fit-content;
  margin: 0.8%;
}

.buttonAlign {
  text-align: right;
  border: 0px;
  position: fixed;
  margin: 0 29px 5px 0;
  bottom: 0px;
  right: 0px;

  button {
    background: #19a04b;
    border-radius: 4px;
    color: #ffffff;
    cursor: pointer;
    border: none;
    font-size: 15px;
    padding: 10px 16px;
  }
}

Table {
  font-size: 16px;
  font-family: cursive;
}

.card-container {
  align-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-family: Georgia, 'Times New Roman', Times, serif;

  h1 {
    font-size: 200%;
    background-color: rgb(213, 206, 206);
    width: 27%;
    font-weight: bolder;
    color: black;
  }
}

.syngenta-ant-table-thead tr th {
  font-weight: bold;
  color: black
}

.displayNone {
  visibility: hidden
}

.label-text-color {
  color: #14151c;
  font-weight: 600;
}
.syngenta-ant-modal-content {
  border-radius: 0px !important;
  padding: 0;
}

.syngenta-ant-modal-header {
  padding: 24px 24px 8px;
  border-bottom: none;
}

.syngenta-ant-modal-title {
  color: #14151c !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}

.syngenta-ant-modal-body {
  padding: 0 24px;
  font-size: 14px;
  font-weight: 400;
}

// .syngenta-ant-form-item-label>label {
//   color: #14151c !important;
//   font-weight: 600;
// }

.syngenta-ant-form-item-label>label::after {
  content: "";
}

.syngenta-ant-modal-footer {
  padding: 10px 24px 24px;
}

.syngenta-ant-btn,
.syngenta-ant-btn:hover,
.syngenta-ant-btn:active,
.syngenta-ant-btn:focus {
  background: transparent;
  color: #14151c ;
  // border: 1px solid #c2c7d0 !important;
  border-radius: 4px;
  font-weight: 400;
}

.syngenta-ant-btn-primary,
.syngenta-ant-btn-primary:hover,
.syngenta-ant-btn-primary:active,
.syngenta-ant-btn-primary:focus {
  color: #fff;
  background: #19a04b !important;
  border: none;
}

.syngenta-ant-radio-wrapper {
  margin: 5px 0px
}

.flex-direction-column {
  flex-direction: column;
  display: flex;
}
.input-field-required-mark::before{
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  line-height: 1;
  content: '*';
}
.field-label {
  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #696f88;
}

.field-value {
  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2f3031
}


.ant-notification {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0 24px 0 0;
  padding: 0;
  color: #000000d9;
  font-size: 14px;
  font-variant: tabular-nums;
  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: fixed;
  z-index: 1010;
}

.ant-notification-notice {
  display: flex;
  align-items: center;
  padding: 16px 24px;
  position: relative;
  width: 384px;
  max-width: calc(100vw - 48px);
  margin-left: auto;
  overflow: hidden;
  line-height: 1.5715;
  word-wrap: break-word;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d, -4px 0 0 0 #19A04B;
}

.ant-notification-notice-close {
  position: absolute;
  right: 15px;
  color: #00000073;
  outline: none;
}

.ant-notification-notice-icon-error {
  color: #EB4B4B !important;
}

.ant-notification-notice-icon {
  color: #19A04B;

  svg {
    width: 20px;
    height: 20px;
  }
}

.ant-notification-notice-description {
  width: 290px;
  margin-left: 10px !important;
}

.ant-notification-notice-message {
  margin-left: 0px !important;

}

.ant-notification-notice-with-icon {
  display: flex;
  align-items: center;
}
.@{ant-prefix}-table-thead tr th {
  font-weight: bold;
  color: black
}
.syngenta-ant-select-selection-item {
  background: white !important;
}
.syngenta-ant-switch.syngenta-ant-switch-checked {
  background: #97be97;
  width: 44px;
}

.syngenta-ant-switch {
  width: 45px;

  .syngenta-ant-switch-inner {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
  }
}
.syngenta-ant-tooltip-placement-top {
  padding-bottom: 0px  !important;

  .syngenta-ant-tooltip-arrow {
    transform: translateX(-50%) translateY(100%) rotate(180deg);
  }
}
.syngenta-ant-select-multiple .syngenta-ant-select-selection-item {
  padding-inline-start: 0px !important
}

.cursor-pointer {
  cursor: pointer;
}

.partner-module,.reports-module  {
  .syngenta-ant-table-row-expand-icon {
    border: 1px solid #c2c7d0;
    color: #4D5165 !important;
    border-radius: 0px;
  }
}
.expanded-row>td {
  background-color: #DFE2E7 !important;
}