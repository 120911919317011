.syt-antd-menu-item svg {
    fill: #696F88;
}
.syt-antd-menu-item-selected svg {
    fill: #FFFFFF;
}
.syt-antd-menu-item-active svg {
    fill: #FFFFFF;
}
.syngenta-ant-layout-sider {
    height: 100vh;
}

.syngenta-ant-menu-item-selected .sidebar-icon {
    filter: brightness(0) invert(1);
}

.syngenta-ant-menu-item {
    padding-left: 24px !important;
}

.syngenta-ant-menu-item-selected::before { 
    content: " ";
    color: #FFFFFF;
    position: absolute;
    width: 4px;
    left: 0px;
    top: 4px;
    bottom: 4px;
    background: #FFFFFF;
    border-radius: 0px 4px 4px 0px;
}

.syt-antd-tooltip-inner {
    display: none;
}
.syngenta-ant-menu.syngenta-ant-menu-dark .syngenta-ant-menu-item-selected, .syngenta-ant-menu-submenu-popup.syngenta-ant-menu-dark .syngenta-ant-menu-item-selected {
    background-color: #171718 !important;
  }
@ant-prefix: syngenta-ant;