.logoutOverlayBlock {
    width: 320px;
    min-height: 120px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;    
    margin-top: 10px;
    right: 10px;
    position: absolute;
    top: 38px;
    z-index: 1000;
}
.cropwise-sustainability--logo{
    height: 34px;
    width: 34px;
}

.profileInfo {
    padding: 15px 15px;
    border-bottom: 1px solid #DFE2E7;    
}
.profileInfo h3 {
    font-weight: 600;
    color: #14151C;
}
.profileUserData {
    margin-top: 10px;
    margin-bottom: 10px;
}
.profileUser {
    width: 24px;
    height: 24px;
    font-family: 'Noto Sans', sans-serif;
    font-size: 12px;
    text-align: center;
    color: #4D5165;
    border: 1px solid #E8EAED;
    border-radius: 50%;
    margin-right: 10px;
    padding: 3px 3px;
    font-weight: 600;
    background: #F2F4F6;
    float: left;
}
.profileUserData span {
    color: #14151C;
}
.logoutLink {
    padding: 12px 15px 15px 15px;
}
.logoutLink span {
    color: #CF3537;
    cursor: pointer;
}
.logoutLink span:hover {
    color: #ee3f42; 
}

.profileInfo .syngenta-ant-radio-button-wrapper {
    cursor: pointer;
}
.syt-antd-dropdown-hidden {
    display: none;
}
@ant-prefix: syngenta-ant;